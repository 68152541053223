import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
    Flex,
    Box,
    Heading,
    Text,
    Link,
    LinkBox,
    LinkOverlay
} from '@chakra-ui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ChevronRightIcon } from '@chakra-ui/icons';
import DriggsLogo from './city-of-driggs-logo-white.svg'

const NewsCard = (props) => {
    console.log(props);
    return (
        <LinkBox as="Box" p="2" w={{base: '100%', md: '33%'}}>
           <Box position="absolute" top="0" left="0" zIndex="100" bg="#134053b6" mt="2" ml="2" style={{borderTopLeftRadius: '0.5rem' }}>
            <Box p="2">
                {props.tags.map((tag, index) => {
                    return <Text as="span" color="white" fontSize="0.85rem" key={index} fontFamily="proxima-nova">{tag}{index < props.tags.length - 1 && ', '}</Text>
                })}
            </Box>
            </Box>
            <Flex flexDirection="column"
                flexGrow="1"
                justifyContent="space-between"
                color="white"
                textAlign="center"
                h="100%"
                bg="elephant.500"
                borderRadius="lg"
            >
           <Box maxH="257px">
            {props.image ? (
                <GatsbyImage image={getImage(props.image)} alt={props.alt} style={{borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem', maxHeight: '257px'}}/>
            ) : (
                <Box h="257px" w="100%" bg="elephant.500" p="4" borderTopRightRadius="0.5rem" borderTopLeftRadius="0.5rem">
                    <img src={DriggsLogo} alt="City of Driggs Logo" style={{width: '100%', height: '100%', objectFit: 'contain', }} />
                </Box>
            )}
                
            </Box>
                <Flex flexDirection="column" justifyContent="space-between" height="100%">
                    <Box p="2">
                            <Heading as="h3" size="lg" textAlign="center" my="2">
                                {props.title}
                            </Heading>
                    </Box>
                    <Box p="2">    <Text color="white">{props.excerpt}</Text></Box>
                    <Box my="2">
                        <LinkOverlay color="white" _hover={{color: 'happy-yellow.500'}} _active={{color: 'happy-yellow.500'}} to={props.url} as={GatsbyLink} fontSize="2xl">
                            Read more <ChevronRightIcon />
                        </LinkOverlay>
                    </Box>
                </Flex>
            </Flex>
        </LinkBox>
    );
}

export default NewsCard;